import "./styles/index.scss";
import {
  createTheme,
  CssBaseline,
  PaletteColor,
  ThemeProvider,
} from "@mui/material";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import Landing from "./features/landing-page/Landing";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Terms from "./features/policies/Terms";
import Privacy from "./features/policies/Privacy";

declare module "@mui/material/styles" {
  interface Palette {
    white: string;
  }
  interface PaletteOptions {
    white: PaletteColor;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white: true;
  }
}

/* Material UI theme settings */
const { palette } = createTheme();
const theme = createTheme({
  palette: {
    background: {
      default: "#EBEBEB",
    },
    text: {
      primary: "#1a1a1a",
    },
    primary: { main: "#1b3d6d" },
    secondary: { main: "#6aa2b8" },
    error: { main: "#da0222" },
    white: palette.augmentColor({
      color: {
        main: "#fff",
      },
    }),
  },
  typography: {
    button: {
      textTransform: "none",
    },
    "fontFamily": "OpenSans",
  },
});

function App() {
  const isProduction = process.env.NODE_ENV === "production";
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<div>Loading...</div>} //If not pass, nothing appears at the time of new version check.
    >
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <Router>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/termsconditions" element={<Terms />} />
              <Route path="/privacypolicy" element={<Privacy />} />
              <Route path="*" element={<Landing />} />
            </Routes>
          </ThemeProvider>
        </Router>
      </div>
    </CacheBuster>
  );
}

export default App;
