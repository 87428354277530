import Markdown from "react-markdown";


export default function Privacy(): JSX.Element {
  const text = `
Effective Date: May 15, 2024 [v.1]

This Privacy Policy outlines how PapyrusAI ("we", "us", or "our") collects, uses, and protects the personal information of its users ("you" or "your") when you use PapyrusAI.org (the "Site") designed to support teaching and learning through generative AI tools and resources. By using our Site, you consent to our privacy policy.

**Before the Site is used by, or personal information is collected from, anyone under 13, we require that either the child’s school (if the site is being used for school) or a parent or legal guardian of the child: (1) receive notice of our personal information collection, use, and disclosure practices; and (2) authorize any collection, use, and/or disclosure of the personal information.**

1. Information Collection. We collect information from you when you register on our Site, use our services, or interact with our features. We strive to collect only data that is necessary for the purpose of improving student achievement and learning and to not use data in ways that are incompatible with these purposes without user consent. The types of information we may collect include:
    1. Personal Identification Information: Name, email address, educational institution, and classes joined.
    2. Generated Content: Your input to PapyrusAI including texts, essays, and other materials you upload as well as the output provided to you by our tool.
    3. Usage Data: Information on how you access and use the Site, including your interaction with our content and services.
2. Use of Information. The information we collect may be used in the following ways:
    1. To personalize your experience and meet your individual needs.
    2. To improve our website and services based on the feedback and interactions you provide.
    3. To provide customer support and respond to your inquiries.
    4. **For research purposes if you are participating in a research study**. The use of your data in that case will be as described in the IRB study information and consent/assent documents.
3. Information Protection. We implement a variety of security measures to maintain the safety of your personal information:
    1. Encryption and secure servers to store your personal data.
    2. Measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.
    3. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee its absolute security.
4. Information Sharing. We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties without your consent, except in the following circumstances:
    1. To comply with legal obligations, protect our rights, or enforce our site policies.
    2. To trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. For example, we share the text from user-submitted files or pasted-in text with the underlying large language model (e.g., GPT-4) for the purpose of text processing. The large language model does not use data submitted by you to train or improve their models, however. In addition, we use AWS to securely store our data in addition to files submitted and text pasted.
    3. **For research purposes if you are participating in a research study**. The sharing of your data in that case will be as described in the IRB study information and consent/assent documents.
5. Data Retention. We will retain your information only for as long as necessary to fulfill the purposes for which it was collected and as required by applicable laws. If you are participating in a research study, the study documentation will override this provision.
6. Your Rights. You have the right to access, correct, or delete your personal information. You can also object to or restrict our processing of your data. To exercise these rights, please contact us at <ucipapyrusai@gmail.com>, providing the name, email address, and nature of the request. Please note that once we receive your request, we will confirm your identity to protect the privacy of our users. Study participants will have the rights set forth in study documentation.
7. Changes to Our Privacy Policy. We may update this privacy policy periodically. We will notify you of any changes by posting the new privacy policy on this page. We encourage you to review our privacy policy regularly for any changes.
8. Contact Us. If you have any questions regarding this privacy policy, you may contact us using the information on our website.

CONTACT:

<ucipapyrusai@gmail.com>
  `

  return (
    <div className="landing">
      <header className="landing__section-header">
        <a href={"/"} className="landing__section-header__logo" aria-label="PapyrusAI Logo" >
          <span className="for-screen-readers-only">PapyrusAI</span>
          <span className="landing__section-header__logo-dimensions">
            <img src="/dll-logo-noname.png" alt="PapyrusAI logo" />
            <h6 className="landing__section-header__logo-title">PapyrusAI</h6>
          </span>
        </a>
      </header>
      <div className="landing__policies">
        <h3 style={{ marginBottom: "1.5rem" }}>PapyrusAI Privacy Policy</h3>
        <Markdown className={""}>
          {text}
        </Markdown>
      </div>
    </div>
  )
}