import Markdown from "react-markdown";


export default function Terms(): JSX.Element {
  const text = `Effective: May 3, 2024 [v.1]

Thank you for using PapyrusAI! Subject to your compliance with these Terms and Conditions of Use (“**Terms**”), you may access and use our tool. **Before the Site is used by, or personal information is collected from, anyone under 13, we require that either the child’s school (if the site is being used for school) or a parent or legal guardian of the child: (1) receive notice of our personal information collection, use, and disclosure practices; and (2) authorize any collection, use, and/or disclosure of the personal information.**

By using PapyrusAI, you agree to these terms.

1. Acceptance of Terms. By accessing and using this website ("**Site**"), you agree to comply with and be bound by these Terms and Conditions of Use. Please review these terms carefully. If you do not agree with any of these terms, you are prohibited from using or accessing this Site. You confirm that you are the authorized user of this account and have the legal right to access and share your educational information through this platform.
2. Description of Service. The Site provides generative AI-based tools and resources to support teaching and learning. The services include, but are not limited to, content generation, support in planning and revising writing, assistance in completing and collaborating on schoolwork; and creation of instructional resources by educators ("**Service**").
3. Use of Service.
    1. The Service is intended solely for educational purposes to support teaching and learning. You consent to the disclosure of certain educational information to the Site (and its affiliated personnel) for the purpose of improving your educational experience and conducting research.
    2. We are committed to protecting your educational data in compliance with FERPA. Your information will not be shared with third parties without your explicit consent, except as required by law; provided that in the event your use is in connection with a research study, use of your data will be as identified in the applicable Study Information Sheet.
    3. Your submitted Input (as defined below) will be shared with a third party service (currently OpenAI GPT-4) for processing and will be stored on AWS. You should not include any identifying information, including your name, that you don't want shared with either third-party. Finally, the Input will be shared with your instructor, and if they invite a TA to the course they will have access to your work. In the event your use is in connection with a research study, sharing of your data will be as identified in the applicable Study Information Sheet.
    4. You agree to use the Service only for lawful purposes and in compliance with all applicable laws and regulations.
    5. This application is not for use in the European Economic Area, Switzerland, or the UK.
4. User Conduct
    1. You must comply with all applicable laws as well as our Privacy Policy and any other documentation, guidelines, or policies we make available to you. You may not use PapyrusAI (or its Output) for any illegal, harmful, or abusive activity.
    2. You agree not to use the Service to generate content that is illegal, offensive, defamatory, plagiarized, or otherwise objectionable. You must not use any Output relating to a person for any purpose that could have a legal or material impact on that person, such as making credit, educational, employment, housing, insurance, legal, medical, or other important decisions about them.
    3. You agree not to interfere with or disrupt the Service or servers or networks connected to the Service.
    4. You agree not to attempt to gain unauthorized access to any part of the Service or to any other accounts, computer systems, or networks connected to the Service.
5. Your Content. You may provide input to the Service (“**Input**”), and receive output from the Service based on the Input (“**Output**”). Input and Output are collectively “**Content.**” You are responsible for Content, including ensuring that it does not violate any applicable law or these Terms. You are responsible for any content you generate using the Service and for the consequences of sharing or publishing such content. You represent and warrant that you have all rights, licenses, and permissions needed to provide Input to our Services.
    1. Ownership of Content. As between you and the Service provider, and to the extent permitted by applicable law, you (i) retain your ownership rights in Input and (ii) own the Output. We hereby assign to you all our right, title, and interest, if any, in and to Output.
    2. Similarity of Content. Due to the nature of our Services and artificial intelligence generally, output may not be unique and other users may receive similar output from our Services. Our assignment above does not extend to other users’ output or any third party Output.
    3. Output may not always be accurate. You should not rely on Output from our Services as a sole source of truth or factual information, or as a substitute for professional advice. You must evaluate Output for accuracy and appropriateness for your use case, including using human review as appropriate, before using or sharing Output from the Services. In addition, if using the Service to generate educational materials, you are responsible for determining the appropriateness of such materials for the intended use.
    4. Our Use of Content. We may use Content to provide, maintain, develop, and improve our Services, comply with applicable law, enforce our Terms and policies, and keep our Service safe. In the event your use is in connection with a research study, please consult the applicable Study Information Sheet.
    5. Opt Out. If you do not want us to use your Content to train our models, you can opt out by emailing <ucipapyrusai@gmail.com>. Please note that in some cases this may limit the ability of our Services to better address your specific use case. In the event your use is in connection with a research study, please consult the applicable Study Information Sheet.
6. Disclaimers.
    1. The Service is provided on an "as is" and "as available" basis. The Site expressly disclaims all warranties of any kind, whether express or implied.
    2. The Site does not guarantee the accuracy, completeness, or usefulness of any information on the Service and neither endorses nor is responsible for the accuracy or reliability of any opinion, advice, or statement made.

OUR SERVICES ARE PROVIDED “AS IS.” EXCEPT TO THE EXTENT PROHIBITED BY LAW, WE AND OUR AFFILIATES AND LICENSORS MAKE NO WARRANTIES (EXPRESS, IMPLIED, STATUTORY OR OTHERWISE) WITH RESPECT TO THE SERVICES, AND DISCLAIM ALL WARRANTIES INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, NON-INFRINGEMENT, AND QUIET ENJOYMENT, AND ANY WARRANTIES ARISING OUT OF ANY COURSE OF DEALING OR TRADE USAGE. WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, ACCURATE OR ERROR FREE, OR THAT ANY CONTENT WILL BE SECURE OR NOT LOST OR ALTERED.

YOU ACCEPT AND AGREE THAT ANY USE OF OUTPUTS FROM OUR SERVICE IS AT YOUR SOLE RISK AND YOU WILL NOT RELY ON OUTPUT AS A SOLE SOURCE OF TRUTH OR FACTUAL INFORMATION, OR AS A SUBSTITUTE FOR PROFESSIONAL ADVICE.

1. Limitation of Liability. The Site shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from the use or the inability to use the Service.

NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA OR OTHER LOSSES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY UNDER THESE TERMS WILL NOT EXCEED ​​THE GREATER OF THE AMOUNT YOU PAID FOR THE SERVICE THAT GAVE RISE TO THE CLAIM DURING THE 12 MONTHS BEFORE THE LIABILITY AROSE OR ONE HUNDRED DOLLARS ($100). THE LIMITATIONS IN THIS SECTION APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.

Some countries and states do not allow the disclaimer of certain warranties or the limitation of certain damages, so some or all of the terms above may not apply to you, and you may have additional rights. In that case, these Terms only limit our responsibilities to the maximum extent permissible in your country of residence.

1. Modifications to Terms of Use. The Site reserves the right to change these terms at any time. Your continued use of the Service after any changes indicates your acceptance of the new terms.
2. Governing Law. These terms shall be governed by and construed in accordance with the laws of the State of California, without giving effect to any principles of conflicts of law.
3. Contact Information. If you have any questions or concerns about these Terms and Conditions of Use, please contact us through the provided contact information on the Site.
  `

  return (
    <div className="landing">
      <header className="landing__section-header">
        <a href={"/"} className="landing__section-header__logo" aria-label="PapyrusAI Logo" >
          <span className="for-screen-readers-only">PapyrusAI</span>
          <span className="landing__section-header__logo-dimensions">
            <img src="/dll-logo-noname.png" alt="PapyrusAI logo" />
            <h6 className="landing__section-header__logo-title">PapyrusAI</h6>
          </span>
        </a>
      </header>
      <div className="landing__policies">
        <h3 style={{ marginBottom: "1.5rem" }}>Terms and Conditions of Use</h3>
        <Markdown className={""}>
          {text}
        </Markdown>
      </div>
    </div>
  )
}